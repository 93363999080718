<!--数据对照-->
<template>
  <div class="container compare-box">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-left">
          <div class="img">
            <img src="../../assets/img/img-n15.png" alt=""/>
          </div>
          固体废物特征与资源化技术数据可视化分析
        </h1>
      </div>
      <div class="col-md-12">
        <div class="tab-part">
          <div :class="{ active: activeIndex == 1 }" @click="tabClick(1)">
            固体废物特征
          </div>
          <div :class="{ active: activeIndex == 2 }" @click="tabClick(2)">
            固体废物资源化技术
          </div>
        </div>
      </div>
    </div>
    <div class="classify-cont">
      <div class="row">
        <div class="col-md-4">
          <div class="part-c">
            <div class="tit">固废来源</div>
            <div class="t1">
              <img src="../../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content1"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities1"
                  @change="handleCheckedCitiesChange1"
              >
                <el-checkbox
                    v-for="(city, index) in options.wasteSource"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(1)">
                全部选中
              </div>
              <div @click="eliminate(1)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities1"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 1)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="part-c">
            <div class="tit">固废大类</div>
            <div class="t1">
              <img src="../../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content2"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities2"
                  @change="handleCheckedCitiesChange2"
              >
                <el-checkbox
                    v-for="(city, index) in options.wasteBigType"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(2)">
                全部选中
              </div>
              <div @click="eliminate(2)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities2"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 2)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="part-c">
            <div class="tit">固废小类</div>
            <div class="t1">
              <img src="../../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content3"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities3"
                  @change="handleCheckedCitiesChange3"
              >
                <el-checkbox
                    v-for="(city, index) in options.wasteSmallType"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(3)">
                全部选中
              </div>
              <div @click="eliminate(3)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities3"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 3)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </div>
        <div class="col-md-4" v-show="activeIndex == 1">
          <div class="part-c">
            <div class="tit">年份</div>
            <div class="t1">
              <img src="../../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content4"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities4"
                  @change="handleCheckedCitiesChange4"
              >
                <el-checkbox
                    v-for="(city, index) in options.years"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(4)">
                全部选中
              </div>
              <div @click="eliminate(4)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities4"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 4)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </div>
        <div class="col-md-4" v-show="activeIndex == 1">
          <div class="part-c">
            <div class="tit">区域</div>
            <div class="t1">
              <el-cascader
                  v-model="searchModel.areas"
                  :options="options.area"
                  :props="{
                  multiple: true,
                  checkStrictly: true,
                  emitPath: false,
                }"
                  clearable
                  collapse-tags
              ></el-cascader>
            </div>
          </div>
          <div class="part-c">
            <div class="tit">指标</div>
            <el-select
                v-model="searchModel.indicator"
                collapse-tags
                placeholder="请选择"
            >
              <el-option
                  v-for="item in options.indicators"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="part-c">
            <div class="tit">统计函数</div>
            <el-select
                v-model="searchModel.function"
                collapse-tags
                placeholder="请选择"
            >
              <el-option
                  v-for="item in options.functions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-md-4" v-show="activeIndex == 2">
          <!--<div class="part-c">
            <div class="tit">资源化产品大类</div>
            <div class="t1">
              <img src="../../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content5"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities5"
                  @change="handleCheckedCitiesChange5"
              >
                <el-checkbox
                    v-for="(city, index) in options.productBigType"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(5)">
                全部选中
              </div>
              <div @click="eliminate(5)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities5"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 5)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>-->
        </div>
        <div class="col-md-4" v-show="activeIndex == 2">
          <!--<div class="part-c">
            <div class="tit">资源化产品小类</div>
            <div class="t1">
              <img src="../../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content6"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities6"
                  @change="handleCheckedCitiesChange6"
              >
                <el-checkbox
                    v-for="(city, index) in options.productSmallType"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(6)">
                全部选中
              </div>
              <div @click="eliminate(6)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities6"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 6)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>-->
        </div>
        <div class="col-md-4" v-show="activeIndex == 2">
          <!--<div class="part-c">
            <div class="tit">资源化技术</div>
            <div class="t1">
              <img src="../../assets/img/img-n22.png" alt=""/>
              <input placeholder="输入搜索内容" v-model="content7"/>
            </div>
            <div class="t2">
              <el-checkbox-group
                  v-model="checkedCities7"
                  @change="handleCheckedCitiesChange7"
              >
                <el-checkbox
                    v-for="(city, index) in options.productTech"
                    :label="city.label"
                    :key="index"
                >{{ city.label }}
                </el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="t3">
              <div class="active" @click="handleCheckAllChange(7)">
                全部选中
              </div>
              <div @click="eliminate(7)">清除选择</div>
            </div>
            <div class="t4">
              <el-tag
                  :key="tag"
                  v-for="tag in checkedCities7"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag, 7)"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>-->
        </div>
        <div class="col-md-12">
          <div class="query-but" @click="selectData">
            <img src="../../assets/img/img-n22.png" alt=""/>查询结果
          </div>
        </div>
      </div>
    </div>
    <el-row v-if="activeIndex == 2">
      <b-card class="col-ms-12 search-data" header="技术评价影响评分图">
        <div ref="charts2" class="echarts-content"></div>
      </b-card>

      <b-card class="col-ms-12 search-data" header="各技术分组平均图">
        <div ref="charts-avg" class="echarts-content"></div>
      </b-card>

      <b-card class="col-ms-12 indicator-data" header="指标详情">
        <h3>{{ this.indicatorDetail.techName }}</h3>
        <div class="r">
          <el-row :gutter="20">
            <el-col :span="24" class="title">
              资源影响（R）
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12" class="title">
              资源消耗
            </el-col>
            <el-col :span="12" class="title">
              综合利用
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="3">
              R1.处理单位固废的原辅料消耗量: <span>{{ this.indicatorDetail.indResR1 }}</span>
            </el-col>
            <el-col :span="3">
              R2.处理单位固废的综合能耗: <span>{{ this.indicatorDetail.indResR2 }}</span>
            </el-col>
            <el-col :span="3">
              R3.处理单位固废的新鲜水消耗量: <span>{{ this.indicatorDetail.indResR3 }}</span>
            </el-col>
            <el-col :span="3">
              R4.处理单位固废的土地占用: <span>{{ this.indicatorDetail.indResR4 }}</span>
            </el-col>
            <el-col :span="3">
              R5.固废资源化率: <span>{{ this.indicatorDetail.indResR5 }}</span>
            </el-col>
            <el-col :span="3">
              R6.战略资源回收的稀缺性贡献: <span>{{ this.indicatorDetail.indResR6 }}</span>
            </el-col>
            <el-col :span="3">
              R7.水循环利用率: <span>{{ this.indicatorDetail.indResR7 }}</span>
            </el-col>
            <el-col :span="3">
              R8.余热利用量: <span>{{ this.indicatorDetail.indResR8 }}</span>
            </el-col>
          </el-row>
        </div>
        <div class="en">
          <el-row :gutter="20">
            <el-col :span="24" class="title">
              环境影响（En）
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24" class="title">
              全生命周期环境影响(En)
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              En1.气候变化: <span>{{ this.indicatorDetail.indEnvironE1 }}</span>
            </el-col>
            <el-col :span="6">
              En2.酸化: <span>{{ this.indicatorDetail.indEnvironE2 }}</span>
            </el-col>
            <el-col :span="6">
              En3.光化学污染: <span>{{ this.indicatorDetail.indEnvironE3 }}</span>
            </el-col>
            <el-col :span="6">
              En4.人体毒性: <span>{{ this.indicatorDetail.indEnvironE4 }}</span>
            </el-col>
            <el-col :span="6">
              En5.富营养化: <span>{{ this.indicatorDetail.indEnvironE5 }}</span>
            </el-col>
            <el-col :span="6">
              En6.生态毒性: <span>{{ this.indicatorDetail.indEnvironE6 }}</span>
            </el-col>
          </el-row>
        </div>
        <div class="ec">
          <el-row :gutter="20">
            <el-col :span="24" class="title">
              经济影响（Ec）
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24" class="title">
              经济可持续性
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              Ec1.销售净利润率: <span>{{ this.indicatorDetail.indEconomyE1 }}</span>
            </el-col>
            <el-col :span="6">
              Ec2.净现值: <span>{{ this.indicatorDetail.indEconomyE2 }}</span>
            </el-col>
            <el-col :span="6">
              Ec3.处理单位固废的投资建设费用: <span>{{ this.indicatorDetail.indEconomyE3 }}</span>
            </el-col>
            <el-col :span="6">
              Ec4.处理单位固废的运行成本: <span>{{ this.indicatorDetail.indEconomyE4 }}</span>
            </el-col>
          </el-row>
        </div>
        <div class="s">
          <el-row :gutter="20">
            <el-col :span="24" class="title">
              社会影响（S）
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12" class="title">
              当地社区与社会影响
            </el-col>
            <el-col :span="12" class="title">
              职工安全与福利
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              S1.单位投资提供的就业岗位: <span>{{ this.indicatorDetail.indSocietyS1 }}</span>
            </el-col>
            <el-col :span="6">
              S2.循环经济示范性:<span>{{ this.indicatorDetail.indSocietyS2 }}</span>
            </el-col>
            <el-col :span="6">
              S3.公众对技术实施的态度: <span>{{ this.indicatorDetail.indSocietyS3 }}</span>
            </el-col>
            <el-col :span="6">
              S4.职业暴露风险:<span>{{ this.indicatorDetail.indSocietyS4 }}</span>
            </el-col>
          </el-row>
        </div>
      </b-card>
    </el-row>

    <el-row v-show="activeIndex == 1">
      <b-card class="col-ms-12 search-data" header="指标年份地理分布图">
        <b-form-group label="年份" label-cols-sm="5" label-align-sm="right">
          <el-select
              v-model="searchModel.geoYear"
              collapse-tags
              style="margin-left: 20px"
              @change="handleGeo"
              placeholder="请选择"
          >
            <el-option
                v-for="item in options.years"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </b-form-group>
        <div ref="geoChart" style="height: 800px; width: 100%"></div>
      </b-card>

      <b-card class="col-ms-12 search-data" header="指标年份趋势图">
        <div ref="charts" class="echarts-content"></div>
      </b-card>
    </el-row>

    <b-card class="col-ms-12 use-data" header="使用说明">{{this.instructions}}</b-card>
  </div>
</template>

<script>
import {
  getWasteSourceSelect,
  getWasteBigTypeSelect,
  getWasteSmallTypeSelect,
  getAreaSelect,
  getProductBigSelect,
  getProductSmallSelect,
  getProductTechSelect, getIndicators, getFeatureYearSelect, getIndicatorsDetail,
} from "@/api/common";
import {compare} from "@/api/waste";
import {compare2, compareAvg} from "@/api/product";
import chinaGeo from "@/assets/json/china-full.json";

export default {
  name: "compare",
  data() {
    return {
      charts2: "",
      chartsAvg: "",
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
      content6: "",
      content7: "",
      checkedCities1: [],
      checkedCities2: [],
      checkedCities3: [],
      checkedCities4: [],
      checkedCities5: [],
      checkedCities6: [],
      checkedCities7: [],
      activeIndex: 1,
      valueZb: "",
      charts: "",
      compareData: "",
      searchModel: {
        sources: [],
        areas: [],
        wasteBigTypes: [],
        wasteSmallTypes: [],
        years: [],
        indicator: "",
        function: "sum",
        geoYear: "",
        productBigTypes: [],
        productBigType: [],
        productSmallTypes: [],
        productTechs: [],
      },
      options: {
        wasteSourceFil: [],
        wasteSource: [],
        wasteBigTypeFil: [],
        wasteBigType: [],
        wasteSmallTypeFil: [],
        wasteSmallType: [],
        area: [],
        yearsFil: [],
        years: [],
        productBigTypeFil: [],
        productBigType: [],
        productSmallTypeFil: [],
        productSmallType: [],
        productTechFil: [],
        productTech: [],
        indicators: [],
        functions: [
          {
            label: "求和",
            value: "sum",
          },
          {
            label: "平均",
            value: "avg",
          },
          {
            label: "最大",
            value: "max",
          },
          {
            label: "最小",
            value: "min",
          },
          {
            label: "中位数",
            value: "median",
          },
          {
            label: "高频",
            value: "hf",
          },
          {
            label: "总体方差",
            value: "vp",
          },
        ],
        map: {},
      },
      table: {
        tableData: [],
        total: 0,
        currentPage: 1,
        pageCount: 0,
        pageSizes: 20,
      },
      mapOptions: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          formatter: function (data) {
            let rt = []
            for (let i = 0; i < data.length; i++) {
              //x轴名称
              let name = data[i].name
              //图表title名称
              let seriesName = data[i].seriesName
              //值
              let value = data[i].value
              let marker = data[i].marker

              if (value != 0 && value != '-') {
                if (rt.length % 8 === 0) {
                  rt.push('<br />')
                }
                rt.push(marker + seriesName + ' ' + name + ': ' + value)
              }
            }
            return rt
          }
        },
        toolbox: {
          top: '30',
          feature: {
            dataView: {show: true, readOnly: false},
            magicType: {show: true, type: ["line", "bar"]},
            restore: {show: true},
            saveAsImage: {show: true},
          },
        },
        legend: {
          type: 'scroll',
          left: '2%',
          right: '2%',
          data: [],
        },
        grid: {
          top: '20%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: ["2016", "2017", "2018", "2019"],
        },
        yAxis: {
          type: "value",
          name: "数值",
          data: [100, 200],
          axisLabel: {
            formatter: "{value} 万吨",
          },
        },
        series: [
          {
            data: [],
            type: "line",
          },
        ],
      },
      mapOptions2: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        toolbox: {
          feature: {
            dataView: {show: true, readOnly: false},
            magicType: {show: true, type: ["line", "bar"]},
            restore: {show: true},
            saveAsImage: {show: true},
          },
        },
        legend: {
          data: ["资源效率", "环境影响", "经济可行", "社会效应"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        axisLabel: {
          interval: 0,
          formatter: function (value) {
            var ret = ""; //拼接加\n返回的类目项
            var maxLength = 8; //每项显示文字个数
            var valLength = value.length; //X轴类目项的文字个数
            var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
            if (rowN > 1) {
              //如果类目项的文字大于5,
              for (var i = 0; i < rowN; i++) {
                var temp = ""; //每次截取的字符串
                var start = i * maxLength; //开始截取的位置
                var end = start + maxLength; //结束截取的位置
                //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                temp = value.substring(start, end) + "\n";
                ret += temp; //凭借最终的字符串
              }
              return ret;
            } else {
              return value;
            }
          },
        },
        xAxis: [
          {
            // axisLabel: {
            //   rotate: 30,
            //   interval: 0
            // },
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [],
      },
      indicatorDetail: {},
      instructions: ''
    };
  },
  watch: {
    content1(val) {
      this.options.wasteSource = this.options.wasteSourceFil.filter((p) => {
        return p.label.indexOf(val) !== -1;
      });
    },
    content2(val) {
      this.options.wasteBigType = this.options.wasteBigTypeFil.filter((p) => {
        return p.label.indexOf(val) !== -1;
      });
    },
    content3(val) {
      this.options.wasteSmallType = this.options.wasteSmallTypeFil.filter(
          (p) => {
            return p.label.indexOf(val) !== -1;
          }
      );
    },
    content4(val) {
      this.options.years = this.options.yearsFil.filter((p) => {
        return p.label.indexOf(val) !== -1;
      });
    },
    content5(val) {
      this.options.productBigType = this.options.productBigTypeFil.filter(
          (p) => {
            return p.label.indexOf(val) !== -1;
          }
      );
    },
    content6() {
      this.options.productSmallType = this.options.productSmallTypeFil.filter(
          (p) => {
            return p.label.indexOf(val) !== -1;
          }
      );
    },
  },
  mounted() {
    this.initChart();
    this.init();
  },
  methods: {
    tabClick(i) {
      let self = this
      self.activeIndex = i;
      self.queryTableData2();
      self.queryInstructions()
    },
    handleData(data1, data2) {
      let labelArr = [];
      data1.forEach((item) => {
        data2.forEach((item2) => {
          if (item.label == item2) {
            labelArr.push(item.value);
          }
        });
      });
      return labelArr;
    },
    handleCheckedCitiesChange1(val) {
      this.searchModel.sources = val;
      this.queryWasteBigSelect();
    },
    handleCheckedCitiesChange2(val) {
      this.searchModel.wasteBigTypes = this.handleData(
          this.options.wasteBigType,
          val
      );
      this.queryWasteSmallSelect();
      this.queryIndicators();
      this.queryYearSelect()

      this.searchModel.indicator = ''
    },
    queryIndicators() {
      getIndicators(this.searchModel.wasteBigTypes).then((data) => {
        this.options.indicators = data;
      });
    },
    handleCheckedCitiesChange3(val) {
      this.searchModel.wasteSmallTypes = this.handleData(
          this.options.wasteSmallType,
          val
      );
    },
    handleCheckedCitiesChange4(val) {
      this.searchModel.years = this.handleData(this.options.years, val);
    },
    handleCheckedCitiesChange5(val) {
      this.searchModel.productBigTypes = this.handleData(
          this.options.productBigType,
          val
      );
      this.queryProductSmallSelect();
    },
    handleCheckedCitiesChange6(val) {
      this.searchModel.productSmallTypes = this.handleData(
          this.options.productSmallType,
          val
      );
      this.queryProductTechSelect(this.options.productSmallType);
    },
    handleCheckedCitiesChange7(val) {
      this.searchModel.productTechs = this.handleData(
          this.options.productTech,
          val
      );
    },
    //全选
    handleCheckAllChange(n) {
      if (n == 1) {
        this.checkedCities1 = [];
        this.options.wasteSource.forEach((item) => {
          this.checkedCities1.push(item.label);
          this.searchModel.sources = this.checkedCities1;
        });
        this.queryWasteBigSelect();
      }
      if (n == 2) {
        this.checkedCities2 = [];
        this.options.wasteBigType.forEach((item) => {
          this.checkedCities2.push(item.label);
        });

        this.searchModel.wasteBigTypes = this.handleData(
            this.options.wasteBigType,
            this.checkedCities2
        );
        this.queryWasteSmallSelect();
        this.searchModel.indicator = ''
      }
      if (n == 3) {
        this.checkedCities3 = [];
        this.options.wasteSmallType.forEach((item) => {
          this.checkedCities3.push(item.label);
          this.searchModel.wasteSmallTypes = this.checkedCities3;
        });
      }
      if (n == 4) {
        this.checkedCities4 = [];
        this.options.years.forEach((item) => {
          this.checkedCities4.push(item.label);
          this.searchModel.years = this.checkedCities4;
        });
      }
      if (n == 5) {
        this.checkedCities5 = [];
        this.options.productBigType.forEach((item) => {
          this.checkedCities5.push(item.label);
          this.searchModel.productBigTypes = this.checkedCities5;
        });
      }
      if (n == 6) {
        this.checkedCities6 = [];
        this.options.productSmallType.forEach((item) => {
          this.checkedCities6.push(item.label);
          this.searchModel.productSmallTypes = this.checkedCities6;
        });
      }
      if (n == 7) {
        this.checkedCities7 = [];
        this.options.productTech.forEach((item) => {
          this.checkedCities7.push(item.label);
          this.searchModel.productTechs = this.checkedCities7;
        });
      }
    },
    //清除
    eliminate(n) {
      if (n == 1) {
        this.checkedCities1 = [];
        this.searchModel.sources = [];
      }
      if (n == 2) {
        this.checkedCities2 = [];
        this.searchModel.wasteBigTypes = [];
      }
      if (n == 3) {
        this.checkedCities3 = [];
        this.searchModel.wasteSmallTypes = [];
      }
      if (n == 4) {
        this.checkedCities4 = [];
        this.searchModel.years = [];
      }
      if (n == 5) {
        this.checkedCities5 = [];
        this.searchModel.productBigTypes = [];
      }
      if (n == 6) {
        this.checkedCities6 = [];
        this.searchModel.productSmallTypes = [];
      }
      if (n == 7) {
        this.checkedCities7 = [];
        this.searchModel.productTechs = [];
      }
    },
    handleClose(tag, n) {
      if (n == 1) {
        this.checkedCities1.splice(this.checkedCities1.indexOf(tag), 1);
      }
      if (n == 2) {
        this.checkedCities2.splice(this.checkedCities2.indexOf(tag), 1);
        this.searchModel.wasteBigTypes.splice(
            this.searchModel.wasteBigTypes.indexOf(tag),
            1
        );
      }
      if (n == 3) {
        this.checkedCities3.splice(this.checkedCities3.indexOf(tag), 1);
        this.searchModel.wasteSmallTypes.splice(
            this.searchModel.wasteSmallTypes.indexOf(tag),
            1
        );
      }
      if (n == 4) {
        this.checkedCities4.splice(this.checkedCities4.indexOf(tag), 1);
        this.searchModel.years.splice(
            this.searchModel.years.indexOf(tag),
            1
        );
      }
      if (n == 5) {
        this.checkedCities5.splice(this.checkedCities5.indexOf(tag), 1);
        this.searchModel.productBigTypes.splice(
            this.searchModel.productBigTypes.indexOf(tag),
            1
        );

      }
      if (n == 6) {
        this.checkedCities6.splice(this.checkedCities6.indexOf(tag), 1);


        this.searchModel.productSmallTypes.splice(
            this.searchModel.productSmallTypes.indexOf(tag),
            1
        );
      }
      if (n == 7) {
        this.checkedCities7.splice(this.checkedCities7.indexOf(tag), 1);
        this.searchModel.productTechs.splice(
            this.searchModel.productTechs.indexOf(tag),
            1
        );
      }
    },
    initChart() {
      // debugger
      this.charts = this.$echarts.init(this.$refs.charts);
      this.charts.setOption(this.mapOptions);
      this.initGeo();

      // this.charts2 = this.$echarts.init(this.$refs.charts2);

      // this.options.map = this.mapOptions2;
      // this.charts2.setOption(this.options.map);
    },
    init() {
      this.queryWasteSourceSelect();
      this.queryAreaSelect();
      this.queryYearSelect();
      this.selectData();
      this.queryProductBigSelect();
      this.queryInstructions()
    },
    queryInstructions(){
      let item = window.localStorage.getItem("instructions")
      if(this.activeIndex == 1){
        this.instructions = JSON.parse(item)['data_analysis_feature']
      }else{
        this.instructions = JSON.parse(item)['data_analysis_tech']
      }
    },
    queryAreaSelect() {
      getAreaSelect().then((data) => {
        this.options.area = data;
      });
    },
    queryProductTechSelect(productSmallType) {
      getProductTechSelect(productSmallType).then((data) => {
        this.options.productTech = data;
        this.options.productTechFil = data;
      });
    },
    queryProductBigSelect() {
      getProductBigSelect().then((data) => {
        this.options.productBigType = data;
        this.options.productBigTypeFil = data;
      });
    },
    queryProductSmallSelect() {
      if (this.searchModel.productBigTypes.length === 0) return;
      getProductSmallSelect(
          this.searchModel.wasteSmallTypes,
          this.searchModel.productBigTypes
      ).then((data) => {
        this.options.productSmallType = data;
        this.options.productSmallTypeFil = data;
      });
    },
    queryYearSelect() {
      getFeatureYearSelect(this.searchModel.wasteBigTypes).then((data) => {
        this.options.years = data;
        this.options.yearsFil = data;
      });
    },
    queryWasteSourceSelect() {
      getWasteSourceSelect().then((data) => {
        this.options.wasteSource = data;
        this.options.wasteSourceFil = data;
      });
    },
    queryWasteBigSelect() {
      // debugger
      if (this.searchModel.sources.length === 0) return;
      getWasteBigTypeSelect(this.searchModel.sources).then((data) => {
        this.options.wasteBigType = data;
        this.options.wasteBigTypeFil = data;
      });
    },
    queryWasteSmallSelect() {
      if (this.searchModel.wasteBigTypes.length === 0) return;
      getWasteSmallTypeSelect(this.searchModel.wasteBigTypes).then((data) => {
        this.options.wasteSmallType = data;
        this.options.wasteSmallTypeFil = data;
      });
    },
    queryTableData(searchParams) {
      if (!searchParams) {
        searchParams = this.searchModel;
      }
      if (this.searchModel.indicator === "") {
        this.$notify({
          title: "提示",
          message: "指标值必选",
        });
        return;
      }
      let self = this;
      let mapOptions = self.mapOptions
      self.charts.setOption(mapOptions);

      compare(this.searchModel).then((data) => {
        // debugger
        if (data) {
          mapOptions.xAxis.data = data.xlabels;
          mapOptions.legend.data = data.legends;
          mapOptions.series.length = 0

          for (let i = 0; i < data.series.length; i++) {
            // if (data.series[i].name === "中国") {
            mapOptions.series[i] = {
              name: data.series[i].name,
              data: data.series[i].data,
              type: "line",
              smooth: true,
            };
            // }
          }
          // console.log(self.mapOptions.legend.data, "data.legends");
          self.charts.setOption(mapOptions);

          self.compareData = data;

          if (data.xlabels.length == 0) {
            self.searchModel.geoYear = "";
          } else {
            self.searchModel.geoYear = data.xlabels[data.xlabels.length - 1];
            self.handleGeo()
          }
        }
      });
    },
    queryTableData2(searchParams) {
      if (!searchParams) {
        searchParams = this.searchModel;
      }
      /*if (this.searchModel.indicator === "") {
        this.$notify({
          title: "提示",
          message: "指标值必选",
        });
        return;
      }*/
      let self = this;
      this.options.map = {};
      compare2(this.searchModel).then((data) => {
        // debugger
        let options = this.mapOptions2;
        // let ids = data.compare.ids
        options.xAxis[0].data = data.compare.xlabels;
        options.legend.data = data.compare.legends;
        for (let i = 0; i < data.compare.series.length; i++) {
          options.series[i] = {
            // id: ids[i],
            name: data.compare.series[i].name,
            data: data.compare.series[i].data,
            type: "bar",
            stack: "影响指标",
            emphasis: {
              focus: "series",
            },
            smooth: true,
          };
        }
        self.charts2 = this.$echarts.init(self.$refs.charts2);
        window.addEventListener("resize", function (event) {
          self.charts2.resize();
        })
        self.charts2.setOption(options);
        self.charts2.on('click', function (params) {
          // console.log(params)
          let {name} = params
          // console.log(seriesId)

          getIndicatorsDetail(name).then(data => {
            self.indicatorDetail = data
          })
        });

        let optionsAvg = this.mapOptions2;
        optionsAvg.xAxis[0].data = data.avg.xlabels;
        optionsAvg.legend.data = data.avg.legends;
        for (let i = 0; i < data.avg.series.length; i++) {
          optionsAvg.series[i] = {
            name: data.avg.series[i].name,
            data: data.avg.series[i].data,
            type: "bar",
            stack: "影响指标",
            emphasis: {
              focus: "series",
            },
            smooth: true,
          };
        }
        self.chartsAvg = this.$echarts.init(self.$refs['charts-avg']);
        window.addEventListener("resize", function (event) {
          self.chartsAvg.resize();
        })
        self.chartsAvg.setOption(optionsAvg);
      });

    },
    selectData() {
      if (this.activeIndex == 1) {
        this.queryTableData();
      } else {
        this.queryTableData2();
      }
    },
    handleCurrentChange(val) {
      const page = {
        pageNo: val,
        pageSize: this.table.pageSizes,
      };
      this.queryTableData(this.searchModel, page);
    },
    initGeo(data) {
      const options = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            if (params.value) {
              return params.name + "<br/>" + params.value + "(万吨)";
            } else {
              return params.name + "<br/>暂无数据";
            }
          },
        },
        visualMap: {
          min: 0,
          max: 500,
          left: "left",
          top: "bottom",
          text: ["高", "低"],
          calculable: true,
          inRange: {
            color: ["lightskyblue", "yellow", "orangered"],
          },
        },
        series: [
          {
            type: "map",
            label: {normal: {show: false}, emphasis: {show: true}},
            map: "china",
            coordinateSystem: "bmap",
            data: data,
            zoom: 1,
            roam: true,
            scaleLimit: {min: 1, max: 10},
          },
        ],
      };

      // debugger
      const geoChart = this.$echarts.init(this.$refs.geoChart);
      this.$echarts.registerMap("china", chinaGeo);
      geoChart.setOption(options);
    },
    handleGeo() {
      let city = this.compareData.legends;
      let years = this.compareData.xlabels;
      let series = this.compareData.series;
      let geoData = [];
      let i;
      for (i = 0; i < years.length; i++) {
        if (this.searchModel.geoYear === years[i]) {
          break;
        }
      }

      for (let j = 0; j < city.length; j++) {
        // debugger
        let vals;
        series.forEach((serie) => {
          if (serie.name === city[j]) {
            vals = serie.data;

            let data = {
              name: city[j],
              value: vals[i],
            };
            geoData.push(data);
          }
        });
      }
      this.initGeo(geoData);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/less/compare.less";
@import "../../assets/less/data.less";

.echarts-content {
  height: 500px;
  width: 100%;
}

.indicator-data {

  div {
    border-radius: 5px;
  }

  .title,h3 {
    text-align: center;
    font-weight: bold;
  }

  .r {
    background-color: #E2EFDA;
  }

  .en {
    background-color: #D9E1F2;
  }

  .ec {
    background-color: #EDEDED;
  }

  .s {
    background-color: #FFF2CC;
  }

  /deep/ .el-row {
    padding: 10px 1%;

    .el-col {
      margin-top: 10px;

      span{
        color: #0d6bac;
      }
    }
  }
}
</style>